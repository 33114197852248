<template>
  <div v-if="isModalOpen" class="common-modal common-modal--dimmed" style="display: block">
    <div class="common-modal__modal">
      <div class="common-modal__content">학습 신청이 완료되었습니다</div>
      <div class="common-modal__buttonWrap">
        <!-- 확인 버튼을 클릭했을 때 closeModal 이벤트 발생 -->
        <button type="button" class="common-modal__button" @click="closeModal">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal'); // 상위 컴포넌트에 closeModal 이벤트 발생
    },
  },
};
</script>

<style lang="scss" scoped>
#tutoringContent {
  overflow: hidden;
}
.inner {
  margin: 0 auto;
  max-width: 450px;
  padding: 0 24px;
}

#classApplyHeader {
  margin-top: 60px;
  font-weight: 700;
  font-size: 26px;
  line-height: 1.15;
  letter-spacing: -1px;
  color: #121214;
}

#description {
  margin-top: 40px;

  .description__card {
    &__list {
      display: flex;
      gap: 9px;
    }
    & {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      height: 110px;
      background: #5d04d9;
      border-radius: 20px;

      &__desc {
        margin-top: 8px;
        font-weight: 500;
        font-size: 13px;
        line-height: 1.31;
        color: #ffffff;
        text-align: center;
        opacity: 0.7;
      }
      &__icon--check {
        width: 24px;
      }
    }
  }

  .description__desc {
    margin-top: 12px;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.69;
    color: #85808d;
  }
}

#checkLevel {
  margin-top: 82px;

  .checkLevel__label {
    display: block;
    font-weight: 700;
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: -0.2px;
    color: #121214;
  }

  .checkLevel__levels {
    margin-top: 12px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 44px;
      border: 1px solid #d6d3dd;
      border-radius: 8px;
      font-weight: 700;
      font-size: 13px;
      line-height: 1.38;
      letter-spacing: -0.2px;
      color: #5e5a66;
    }
    button.checked {
      border: 1px solid #5d04d9;
      color: #5d04d9;
    }
    button + button {
      margin-top: 6px;
    }
  }
}

#applierInfo {
  margin-top: 50px;
  .applierInfo__label {
    display: block;
    font-weight: 700;
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: -0.2px;
    color: #121214;
  }

  .applierInfo__form {
    margin-top: 12px;
    &__block {
      display: flex;
      margin-top: 10px;
    }
    .input__wrapper {
      position: relative;
      flex: 1;

      .input__extra {
        position: absolute;
        right: 16px;
        top: 15px;

        .authCountDown {
          font-weight: 700;
          font-size: 11px;
          line-height: 1.27;
          color: #5e5a66;
        }
        .authCountDown.warn {
          color: #f24b4b;
        }
      }

      .input__message--warn {
        margin-top: 6px;
        font-weight: 400;
        font-size: 11px;
        line-height: 1.27;
        color: #f24b4b;
      }
      .input__message--done {
        display: flex;
        align-items: center;
        margin-top: 6px;
        font-weight: 400;
        font-size: 11px;
        line-height: 1.27;
        color: #5d04d9;

        .icon--done {
          position: relative;
          display: inline-block;
          width: 11px;
          height: 11px;
          justify-content: center;
          margin-right: 4px;

          // check icon
          &::after {
            display: block;
            position: absolute;
            left: 14%;
            top: 12%;
            width: 7px;
            height: 4px;
            border: 2px solid #5d04d9;
            border-top: 0;
            border-right: 0;
            transform: rotate(-45deg);
            content: '';
          }
        }
      }
    }
    .requestAuthBtn {
      height: 44px;
      margin-left: 10px;
      padding: 0 16px;
      border: 1px solid #5d04d9;
      color: #5d04d9;
      border-radius: 8px;
      font-weight: 700;
      font-size: 13px;
      line-height: 1.38;
    }
    .requestAuthBtn.disabled {
      border: 1px solid #d6d3dd;
      color: #5e5a66;
    }
  }
}

#terms {
  margin-top: 52px;

  .term--all {
    &__header {
      display: flex;
      align-items: center;
      cursor: pointer;

      &__title {
        flex: 1;
        margin-left: 9px;
        font-weight: 700;
        font-size: 13px;
        line-height: 1.38;
        letter-spacing: -0.2px;
        color: #121214;
      }
    }
  }

  hr {
    border: 0.5px solid #e8e6ed;
    margin: 18px 0;
  }

  .term--part {
    &__header {
      display: flex;
      align-items: center;

      &__title {
        flex: 1;
        margin-left: 9px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.38;
        letter-spacing: -0.2px;
        color: #3f3d44;
        cursor: pointer;
      }
    }
    &__toggle__btn {
      text-decoration: underline;
      font-weight: 400;
      font-size: 11px;
      line-height: 1.27;
      text-align: center;
      color: #85808d;
      cursor: pointer;
    }
    &__toggle__content {
      box-sizing: border-box;
      max-height: 170px;
      margin-top: 7px;
      font-weight: 400;
      font-size: 11px;
      line-height: 1.27;
      color: #85808d;
      overflow-y: scroll;

      border: 1px solid #f1f0f5;
      border-radius: 10px;
      padding: 15px 0 15px 15px;
    }
    &__toggle__content::-webkit-scrollbar {
      display: block;
      width: 15px;
    }
    &__toggle__content::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      background-color: #e8e6ed;
      border-radius: 2px;
    }
  }
  .term--part + .term--part {
    margin-top: 8px;
  }
}

.applySubmitBtn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background: #5d04d9;
  border-radius: 10px;

  font-weight: 700;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.5px;
  color: #ffffff;

  &.disabled {
    background: #f1f0f5;
    color: #85808d;
  }
}

#tutorProcess {
  margin-top: 74px;
  .tutorProcess__label {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #121214;
  }
  .tutorProcess__cards {
    &--web,
    &--mob {
      margin-top: 30px;
      .tutorProcess__card {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        border: 1px solid #e8e6ed;
        border-radius: 20px;
        img {
          width: 100px;
        }
        .tutorProcess__card__textWrapper {
          margin-left: 12px;
          width: 140px;
          .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 1.38;
            color: #121214;
          }
          .desc {
            margin-top: 7px;
            font-weight: 400;
            font-size: 13px;
            line-height: 1.54;
            color: #85808d;
          }
        }
      }
    }
    @media (max-width: 768px) {
      &--mob {
        display: block;
      }
      &--web {
        display: none;
      }
    }
    @media (min-width: 768px) {
      &--mob {
        display: none;
      }
      &--web {
        display: block;
      }
    }
  }

  .tutorProcess__cards--web {
    .tutorProcess__card {
      width: 100%;
    }
    .tutorProcess__card + .tutorProcess__card {
      margin-top: 10px;
    }
  }

  .tutorProcess__cards--mob {
    position: relative;
    left: -450px;
    width: 1190px;
    min-width: 1190px;
    .tutorProcess__card {
      width: 290px;
      cursor: pointer;
    }
  }

  .linkForTutorPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 30px auto 100px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    color: #121214;
    img {
      margin-top: 10px;
      width: 34px;
    }
  }
}
</style>
