import { render } from "./classApplyNew.vue?vue&type=template&id=485fa530&scoped=true"
import script from "./classApplyNew.vue?vue&type=script&lang=js"
export * from "./classApplyNew.vue?vue&type=script&lang=js"

import "./classApplyNew.vue?vue&type=style&index=0&id=485fa530&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-485fa530"
/* hot reload */
if (module.hot) {
  script.__hmrId = "485fa530"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('485fa530', script)) {
    api.reload('485fa530', script)
  }
  
  module.hot.accept("./classApplyNew.vue?vue&type=template&id=485fa530&scoped=true", () => {
    api.rerender('485fa530', render)
  })

}

script.__file = "src/page/classApply/classApplyNew.vue"

export default script