<template>
  <div class="page-crm-survey">
    <div class="select-buttons-container">
      <div v-if="!this.isInApp">
        <ClassApply />
      </div>
      <div v-if="this.isInApp">
        <QuestionPromptVue />
        <section class="category-container">
          <div class="category-text">문의 유형</div>

          <div class="select-button-item">
            <div v-for="(item, index) in askList" :key="index">
              <SelectButton
                :buttonText="item.optionTitle"
                :isActive="item.checked"
                v-on:click="toggleOption(item.optionId)"
              />
            </div>
          </div>
        </section>
        <section class="category-container1">
          <div class="category-text">상담 가능 시간</div>

          <TimeSelector @time-selected="handleTimeSelected" />
        </section>
        <section class="category-container1">
          <div class="category-text">연락처</div>
          <input class="phone-number" v-model="state.phone" @input="handlePhoneNumberInput" />
        </section>
        <section class="category-container">
          <div class="category-text">알아두세요!</div>
          <ul class="notification-container">
            <li class="notification-text">
              학습 상담 신청 버튼 클릭 시, 튜터링의 이용약관에 동의하게 되며 개인정보 처리방침에 동의하게 됩니다.
            </li>
            <li class="notification-text">
              학습 상담 운영 시간은 평일 10:00~19:00 (점심휴게시간 13:00~14:00 제외)이오니 신청 시 참고 부탁드립니다.
            </li>
            <li class="notification-text">주말 및 공휴일 신청 건은 평일 운영 시간 내로 빠르게 연락 드리겠습니다.</li>
            <li class="notification-text">
              이용약관 동의 여부와 상관 없이 상담 방법에 따른 상세 안내가 SMS로 발송될 수 있습니다.
            </li>
          </ul>
        </section>
        <section class="margin-40">
          <SurveyButtonVue
            :text="'학습 상담 신청'"
            :isChecked="isSubmitButtonEnabled"
            @click="handleSurveyButtonClick"
          />
        </section>
      </div>
      <section class="focus-certificate">
        <div class="promotion-certificate">
          <div class="certificate__tip">
            직장인들을 위한 SPEC UP!
            <br />
            사내 교육지원비로 알뜰하게!
            <div class="certificate__tip__sub">수강증, 출석확인증, 영수증 상시 발급 가능</div>
          </div>

          <button type="button" class="button modal__certificate__open" @click="toggleSample">
            증빙서류 샘플 상세보기
          </button>
        </div>
      </section>
    </div>
  </div>
  <ClassApplySurvey :isModalOpen="isModalOpen" @closeModal="closeModal" />
  <!-- <template> -->
  <div class="modal__background" id="modalCertificateClose" style="display: block" v-if="isSampleClicked">
    <div class="modal__contents__container">
      <div class="main_container">
        <div class="text_container">
          <h1 class="text_heading">튜터링 증빙서류 샘플</h1>
          <p class="text_description">
            수강증, 출석확인증, 영수증 상시 발급 가능
            <br />
            연 수강권의 경우 매월 수강권 발급이 가능합니다.
          </p>
        </div>
        <ul class="image-container">
          <li class="image">
            <img src="//res.tutoring.co.kr/res/images/evt/com/receipt_img01.png" alt="" />
            <div class="under_text">- 수강증 증빙서류 샘플 -</div>
          </li>
          <li class="image">
            <img src="//res.tutoring.co.kr/res/images/evt/com/receipt_img02.png" alt="" />
            <div class="under_text">- 출석 확인증 증빙서류 샘플 -</div>
          </li>
          <li class="image">
            <img src="//res.tutoring.co.kr/res/images/evt/com/receipt_img03.png" alt="" />
            <div class="under_text">- 영수증 증빙서류 샘플 -</div>
          </li>
        </ul>
      </div>
    </div>
    <button type="button" class="close-btn" @click="toggleSample">
      <img src="//res.tutoring.co.kr/res/images/evt/com/close_ico_pc.png" alt="close-btn" />
    </button>
  </div>
  <!-- </template> -->
</template>

<script>
import api from '@/common/api';
import inappTools from '@/common/inappTools';
import ClassApplySurvey from '@/page/classApply/classApplySurvey.vue';
import SelectButton from './SelectBtn.vue';
import SurveyButtonVue from './SurveyButton.vue';
import TimeSelector from './TimeSelector.vue';
import QuestionPromptVue from './QuestionPrompt.vue';
import ClassApply from '../../page/classApply/classApplyNew.vue';

export default {
  components: {
    SelectButton,
    TimeSelector,
    SurveyButtonVue,
    QuestionPromptVue,
    ClassApplySurvey,
    ClassApply,
  },

  data() {
    return {
      isKey: localStorage.getItem('key'),
      isInApp: inappTools.isInApp(),
      isModalOpen: false,
      state: {
        phone: '',
        crmTags: '',
        wish_time: '',
        key: '',
      },
      isSampleClicked: false,
      phoneNumber: '',
      phonePattern: /^01(?:0|1|[6-9])(\d{3}|\d{4})(\d{4})$/,
      askList: [
        { optionTitle: '코스추천', optionId: 246, checked: false },
        { optionTitle: '수강권추천', optionId: 247, checked: false },
        { optionTitle: '학습방법', optionId: 248, checked: false },
        { optionTitle: '자녀학습방법', optionId: 249, checked: false },
      ],
      selectedOptions: [],
    };
  },
  created() {
    this.getPhoneNumber();
  },
  methods: {
    openModal() {
      this.isModalOpen = true; // 모달 열기
    },
    closeModal() {
      this.isModalOpen = false; // 모달 닫기
    },
    async requestAuth() {
      const userKey = localStorage.getItem('key');
      const OS_WEB = 0;
      const response = await api.post('/app/users/setUserGuestContactPhone', {
        phone: this.state.phone,
        os: OS_WEB,
        key: userKey || 0,
      });

      const result = response.data;
      this.state.key = result.key;
    },
    handlePhoneNumberInput(event) {
      // 숫자만 허용하는 정규식을 사용하여 입력값을 검증합니다.
      const phoneNumber = event.target.value.replace(/\D/g, '');

      // 입력값이 11자리 이상이면 첫 11글자만 유지하도록 합니다.
      if (phoneNumber.length >= 11) {
        this.state.phone = phoneNumber.slice(0, 11);
      } else {
        this.state.phone = phoneNumber;
      }
    },
    async handleSurveyButtonClick() {
      if (this.isSubmitButtonEnabled) {
        try {
          if (!localStorage.getItem('key')) {
            await this.requestAuth();
          } else {
            this.state.key = localStorage.getItem('key');
          }
          await this.postInfo(); // postInfo 메서드 호출
          this.openModal();
        } catch (error) {
          console.error('Error sending data:', error);
          // 에러 처리를 원하는 대로 구현하세요.
        }
      } else {
        // 필수 정보가 모두 입력되지 않았을 때 사용자에게 알림을 보여줄 수 있습니다.
        alert('필수 정보를 모두 입력해주세요.');
      }
    },
    toggleSample() {
      this.isSampleClicked = !this.isSampleClicked;
    },
    openSample() {
      this.isSampleClicked = !this.isSampleClicked;
    },
    toggleSelection() {
      this.$emit('toggle', this.optionId); // 부모 컴포넌트로 선택 상태를 전달
    },
    toggleOption(optionId) {
      const index = this.askList.findIndex((item) => item.optionId === optionId);
      if (index !== -1) {
        this.askList[index].checked = !this.askList[index].checked;

        if (this.askList[index].checked) {
          this.selectedOptions.push(optionId); // 선택된 항목을 배열에 추가
        } else {
          const selectedIndex = this.selectedOptions.indexOf(optionId);
          if (selectedIndex !== -1) {
            this.selectedOptions.splice(selectedIndex, 1); // 선택 취소된 항목을 배열에서 제거
          }
        }
      }
    },
    async getPhoneNumber() {
      try {
        const key = localStorage.getItem('key');
        if (key) {
          const result = await api.get(`/app/users/phone`, { key });
          this.state.phone = result.data;
        }
      } catch (error) {
        console.error('Error fetching phone numberaaaa:', error);
      }
    },
    sliceDate(date) {
      if (date !== '언제든지 가능') {
        const code = Number(date.slice(3, 5));
        if (code < 10) {
          return 12 + code;
        }
        return code;
      }
    },
    async postInfo() {
      try {
        const response = await api.post(`/app/users/consulting/request`, {
          phone: this.state.phone,
          crmTags: this.state.crmTags,
          wish_time: this.sliceDate(this.state.wish_time),
          channel: 3,
          ref: 'detail',
          key: this.state.key,
          lang: localStorage.getItem('session.lang') || 'kr',
          mode: localStorage.getItem('appMode') || 'E',
          tzo: 540,
          appMode: localStorage.getItem('appMode') || 'E',
        });
        console.log({ response });
      } catch (error) {
        console.error('Error fetching phone numberaaaa:', error);
      }
    },

    handleTimeSelected(selectedTime) {
      // 선택된 시간을 부모 컴포넌트의 데이터에 할당
      this.state.wish_time = selectedTime;
      // 선택된 시간이 제대로 할당되었는지 확인
      console.log('Selected Time:', this.state.wish_time);
    },
  },
  watch: {
    selectedOptions: {
      handler(newOptions) {
        // selectedOptions 배열이 변경될 때마다 호출됩니다.
        // crmTags에 옵션의 optionId를 추가하고 문자열로 변환하여 저장합니다.
        this.state.crmTags = newOptions.join(',');
      },
      deep: true, // nested 객체/배열까지 감시할 경우 deep: true 설정이 필요합니다.
    },
  },
  computed: {
    isSubmitButtonEnabled() {
      // wish_time, crmTags, phone의 길이가 0 이상이면 true, 그렇지 않으면 false 반환
      return this.state.wish_time.length > 0 && this.state.crmTags.length > 0 && this.state.phone.length > 0;
    },
  },
};
</script>

<style>
.category-container {
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-s, 12px);
  max-width: 1000px;
}
.margin-40 {
  margin-top: 40px;
}
.close-btn {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 93%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.category-container1 {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-s, 12px);
  max-width: 1000px;
}
.page-crm-survey {
  padding: 24px 24px 24px 24px;
}
.category-text {
  color: var(--gray-gray-600, var(--gray-600, #85808d));
  /* Ko / H6 */
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.2px;
}
.modal__background {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
.main_container {
  padding: 40px 20px 55px 20px;
}
.focus-certificate {
  margin-top: 39px;
}
.image-container {
  margin-top: 33px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.notification-container {
  list-style-type: disc; /* 원형 점을 사용하려면 'disc', 다른 유형의 점을 원한다면 해당 값을 사용하세요. */
  padding-left: 20px;
}
.text_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.text_heading {
  color: var(--opacity-black, #121214);
  text-align: center;

  /* H2 */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}
.text_description {
  color: var(--opacity-black-600, rgba(18, 18, 20, 0.6));
  text-align: center;

  /* Body2 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.notification-text {
  color: var(--gray-gray-600, var(--gray-600, #85808d));

  /* Ko / H7 - Regular */
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 127.273% */
}
.select-buttons-container {
  margin: 0 auto;
  max-width: 1000px;
}
.modal__contents__container {
  margin: 84px 20px 84px 20px;
  border-radius: 10px;
  background: var(--opacity-white, #fff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  height: 74%;
  overflow-y: auto;
}
@media (min-width: 500px) {
  .modal__contents__container {
    max-width: 500px;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.phone-number {
  border: none;
  box-sizing: border-box;
  border-bottom: 1px solid gray;
  width: 100%;
  cursor: pointer;
  outline: none;
  padding: 10px 8px;
}

.select-button-item {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  width: 100%;
}
.under_text {
  color: var(--opacity-black-600, rgba(18, 18, 20, 0.6));
  text-align: center;

  /* Body2 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.image {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.image img {
  border-radius: 14px;
  border: 1px solid gainsboro;
}
</style>
<style lang="scss" scoped src="@/assets/newPromotions.scss"></style>
<style lang="scss" scoped src="@/assets/ChatPass.scss"></style>
