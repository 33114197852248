<template>
  <div class="question-prompt">
    <div class="prompt-title">궁금한 점이 있나요?</div>
    <div class="prompt-description">
      학습 전문 컨설턴트가 안내 및 클래스 추천하고
      <br />
      최대 할인 혜택까지 자세히 설명해 드릴게요!
    </div>
  </div>
</template>

<style scoped>
.question-prompt {
  color: #000;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.prompt-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.prompt-description {
  color: var(--opacity-black-600, rgba(18, 18, 20, 0.6));
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
</style>

<script>
export default {
  name: 'QuestionPrompt',
};
</script>
