import { render } from "./PageCRMSurvey.vue?vue&type=template&id=b79d123a&scoped=true"
import script from "./PageCRMSurvey.vue?vue&type=script&lang=js"
export * from "./PageCRMSurvey.vue?vue&type=script&lang=js"

import "./PageCRMSurvey.vue?vue&type=style&index=0&id=b79d123a&lang=css"
import "@/assets/newPromotions.scss?vue&type=style&index=1&id=b79d123a&lang=scss&scoped=true"
import "@/assets/ChatPass.scss?vue&type=style&index=2&id=b79d123a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-b79d123a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "b79d123a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b79d123a', script)) {
    api.reload('b79d123a', script)
  }
  
  module.hot.accept("./PageCRMSurvey.vue?vue&type=template&id=b79d123a&scoped=true", () => {
    api.rerender('b79d123a', render)
  })

}

script.__file = "src/components/PageCRMSurvey/PageCRMSurvey.vue"

export default script