<template>
  <button class="select-button" :class="{ active: isActive }" @click="toggleActive">
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object, // item이라는 prop을 받도록 추가
      required: true,
    },
  },

  methods: {
    handleButtonClick() {
      this.toggleOption(this.item.optionId);
    },
  },
};
</script>

<style scoped>
.select-button {
  /* 기본 스타일 */
  color: var(--gray-gray-700, #5e5a66);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  width: 100%;
  height: 44px;
  padding: 11px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--gray-gray-400, #d6d3dd);
  white-space: nowrap;
}

/* 클릭 시 추가될 클래스의 스타일 */
.select-button.active {
  color: #5d04d9;
  border: 2px solid #5d04d9;
}
</style>
