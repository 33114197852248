import { render } from "./SelectBtn.vue?vue&type=template&id=4f6519cc&scoped=true"
import script from "./SelectBtn.vue?vue&type=script&lang=js"
export * from "./SelectBtn.vue?vue&type=script&lang=js"

import "./SelectBtn.vue?vue&type=style&index=0&id=4f6519cc&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-4f6519cc"
/* hot reload */
if (module.hot) {
  script.__hmrId = "4f6519cc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4f6519cc', script)) {
    api.reload('4f6519cc', script)
  }
  
  module.hot.accept("./SelectBtn.vue?vue&type=template&id=4f6519cc&scoped=true", () => {
    api.rerender('4f6519cc', render)
  })

}

script.__file = "src/components/PageCRMSurvey/SelectBtn.vue"

export default script