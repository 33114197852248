<template>
  <button :class="{ 'submit-button': true, 'checked-button': isChecked }">
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    isChecked: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.submit-button {
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--gray-gray-200, #f1f0f5);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: var(--gray-600, #85808d);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
  transition: background-color 0.3s ease;
}
.checked-button {
  background: var(--primary-600, #5d04d9);
  color: white;
}
</style>
