<template>
  <div class="time-picker-contaier">
    <div
      class="time-picker"
      :class="{ 'is-dropdown-visible': isDropdownVisible, selected: selectedTime }"
      @click="toggleDropdown"
    >
      <div class="time-picker-label">
        <span class="selected-time">{{ selectedTime || '통화 가능한 시간을 선택해주세요.' }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M12 7L8 11L4 7" stroke="#AFABB7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
    <ul v-show="isDropdownVisible" class="time-picker-select">
      <li v-for="(time, index) in timeOptions" :key="index" @click="selectTime(time)">{{ time }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDropdownVisible: false,
      selectedTime: '',
      timeOptions: [
        '언제든지 가능',
        '오전 10시 ~ 11시',
        '오전 11시 ~ 12시',
        '오후 02시 ~ 03시',
        '오후 03시 ~ 04시',
        '오후 04시 ~ 05시',
        '오후 05시 ~ 06시',
        '오후 06시 ~ 07시',
      ],
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    selectTime(time) {
      this.selectedTime = time;
      this.isDropdownVisible = false;
      this.$emit('time-selected', this.selectedTime);
    },
  },
};
</script>

<style scoped>
.time-picker-contaier {
  width: 100%;
}
.time-picker {
  border-radius: 8px;
  border: 1px solid var(--gray-400, #d6d3dd);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  cursor: pointer;
}

/* isDropdownVisible이 true일 때 border 스타일을 변경합니다. */
.time-picker.is-dropdown-visible {
  border: 1px solid #5d04d9;
}
.time-picker.selected {
  border: 1px solid #5d04d9;
}

.time-picker-label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--gray-500, #afaab7);
  font-size: 14px;
  justify-content: space-between;
  font-weight: 500;

  line-height: 20px;
}

.time-picker-select {
  width: 100%;
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.time-picker-select li {
  cursor: pointer;
  margin: 5px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 6px 20px;
}
.selected-time {
  color: #333;
}
</style>
